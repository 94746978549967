<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'Solutions'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.solutionsAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #image="{rowData}">
                <img :src="rowData.image" width="150px" alt="img">
            </template>
            <template #is_enabled="{rowData}">
                <checkbox-input v-model="rowData.is_enabled" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Solution" ref="solutionsAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddSolutions @success="formSuccess"></AddSolutions>
        </modal>

        <modal title="Edit Solution" ref="solutionsEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditSolutions :initial-data="editingItem" @success="formSuccess"></EditSolutions>
        </modal>

        <delete-modal ref="solutionsDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Solution <b v-html="deletingItem && deletingItem.title"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';
import AddSolutions from './AddSolutions';
import EditSolutions from './EditSolutions';

export default {
    name       : 'Solutions',
    computed   : { ...mapGetters(['currentUser']) },
    components : { EditSolutions, AddSolutions },
    data () {
        return {
            listUrl      : urls.admin.solutions.list,
            deleteUrl    : urls.admin.solutions.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : '__slot:image',
                    sortField  : 'image',
                    title      : 'Image',
                    titleClass : ''
                },
                {
                    name       : 'description',
                    sortField  : 'description',
                    title      : 'Description',
                    titleClass : ''
                },
                {
                    name       : 'create_date',
                    sortField  : 'create_date',
                    title      : 'Created At',
                    titleClass : ''
                },
                {
                    name       : '__slot:is_enabled',
                    sortField  : 'is_enabled',
                    title      : 'Enabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.solutionsAddModal.close();
            refs.solutionsEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            delete this.editingItem.solution_items;
            this.editingItem.initial_description = item.solution_items;
            this.$refs.solutionsEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.solutionsDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Solutions..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.solutionsDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            item.api_key = that.currentUser.api_key;
            axios.form(urls.admin.solutions.addEdit, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Solutions..!', 'Success',
                        {
                            type : 'success'
                        });
                    that.$refs.table.refreshTable();
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.is_enabled = !item.is_enabled;
                }
            }).catch(function (err) {
                item.is_enabled = !item.is_enabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
